
import { defineComponent, ref } from 'vue'
import InputField from '@/components/atom/InputField.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    keyword: {
      type: String
    },
    clearButtonVisible: {
      type: Boolean
    }
  },
  components: {
    InputField,
    CustomButton
  },
  setup(props, { emit }) {
    const inputValue = ref(props.keyword ? props.keyword : '')
    const emitSearch = () => {
      emit('onSearch', inputValue.value)
    }
    const clearAllQuery = () => {
      emit('clearAllQuery')
      inputValue.value = ''
    }
    return {
      inputValue,
      emitSearch,
      clearAllQuery
    }
  }
})
