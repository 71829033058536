
import { computed } from 'vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
export default {
  components: {
    VPagination
  },
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    pages: {
      type: Number
    }
  },
  setup(props: any, { emit }: any) {
    const activePage = computed({
      get(): number {
        return props.currentPage
      },

      set(newValue: number) {
        emit('update:modelValue', newValue)
        emit('onChange', newValue)
      }
    })
    const myCallback = (value: number) => {
      emit('updatePagination', value)
    }
    return { myCallback, activePage }
  }
}
