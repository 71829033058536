import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_pagination, {
      modelValue: $setup.activePage,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (($setup.activePage) = $event)),
        $setup.myCallback
      ],
      hideFirstButton: true,
      hideLastButton: true,
      "active-color": "#A1A3A4",
      pages: $props.pages
    }, null, 8, ["modelValue", "pages", "onUpdate:modelValue"])
  ]))
}