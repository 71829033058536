/* prettier-ignore */
export default function () {
  const slugify = (value: string) => {
    value = value
      .toString()
      .replace(/^\s+|\s+$/g, '')
      .toLowerCase()

    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·_,:;'
    const to = 'aaaaaeeeeeiiiiooooouuuunc------'
    for (let i = 0, l = from.length; i < l; i++) {
      value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    value = value
      .replace(/[^a-z0-9 -/]/g, '') // Remove invalid chars
      .replace(/\s+/g, '-') // Remove whitespaces
      .replace(/[^\w\s]/g, '-') // Remove special character
      .replace(/-+/g, '-') // Collapse multiple hyphen's
    return value
  }
  return {
    slugify
  }
}
