
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  emits: ['update:modelValue', 'change', 'onEnter', 'onBlur'],
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      required: false
    },
    type: {
      type: String,
      default: 'text'
    },
    borderRadius: {
      type: Boolean,
      default: true
    },
    textArea: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputValue = ref(props.modelValue ? props.modelValue : '')
    watch(
      () => props.modelValue,
      (newValue) => {
        if (!newValue) {
          inputValue.value = ''
        }
      }
    )
    return {
      inputValue
    }
  }
})
