import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b10dd8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searchbar" }
const _hoisted_2 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputField, {
        onOnEnter: _ctx.emitSearch,
        modelValue: _ctx.inputValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        placeholder: _ctx.placeholder,
        class: "input-field"
      }, null, 8, ["onOnEnter", "modelValue", "placeholder"]),
      _createVNode(_component_CustomButton, {
        buttonText: "ZOEKEN",
        onOnClick: _ctx.emitSearch
      }, null, 8, ["onOnClick"]),
      (_ctx.clearButtonVisible)
        ? (_openBlock(), _createBlock(_component_CustomButton, {
            key: 0,
            class: "mg-left",
            buttonText: "CLEAR FILTERS",
            onOnClick: _ctx.clearAllQuery
          }, null, 8, ["onOnClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}