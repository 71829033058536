
import { defineComponent, computed } from 'vue'

export default defineComponent({
  emits: ['onChange', 'update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: 10
    }
  },
  setup(props, { emit }) {
    const value = computed({
      get(): number {
        return props.modelValue
      },
      set(newValue: number) {
        emit('update:modelValue', newValue)
        emit('onChange', newValue)
      }
    })
    return { value }
  }
})
